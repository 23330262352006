<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Tags
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
						span Filters
						i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
					b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								.form-group
									label Search
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
								.form-group
									label OS
									select.form-control(v-model="filters.platform")
										option(value="") - All -
										option(value="ios") iOS
										option(value="android") Android
								.form-group
									label Countries
									input.form-control.input-search(type="text", placeholder="", v-model="filters.countries")
								.form-group
									label Status
									select.form-control(v-model="filters.status")
										option(v-for="o in offerStatusOptions", :value="o.v") {{ o.t }}
								// select-advertiser(v-model="filters.advertiser_id")
								.form-group.no-label
									button.btn.btn-primary(type="submit") Go
							.form-row-end.col-auto
								//select-advertiser(v-model="addAdvertiser", :multiple="false", @input="addOffer")
								.form-group.no-label
									router-link.btn.btn-success(:to="{name:'tag-add'}")
										i.flaticon2-plus
										| Add

				p.clearfix &nbsp;

		.card.card-custom.gutter-b
			.card-body
				.table-wrapper
					table.table.table-bordered
						thead
							tr
								th &nbsp;
								th ID
								th Publisher
								th Status
								th Name
								//th Placement
								//th Offer
								th(style="padding-top: 0; padding-bottom: 0; vertical-align: middle; ") &nbsp;
									//b-dropdown(text="Bulk Actions", size="sm", variant="secondary")
										b-dropdown-item(href="#", @click="bulkConnect()") Connect
										b-dropdown-item(href="#", @click="bulkPause()") Pause
										b-dropdown-item(href="#", @click="bulkActivate()") Activate
										b-dropdown-item(href="#", @click="bulkExport()") Export
										b-dropdown-item(href="#", @click="bulkStatistics()") View statistics
										b-dropdown-item(href="#", @click="bulkModal('daily_click_cap', 'Set Click Cap')") Set Click Cap
						tbody
							tr(v-if="records.length === 0")
								td(colspan="6") No matching records were found
							tr(v-for="r in (selectedTab==='bucket' ? bucket : records)", v-bind:key="r.id", :class="{'row-selected': bucketHash[r.id]}")
								td.col-select(:class="{'bucket-row':bucketHash[r.id]}")
									a(href="javascript:void(0)", @click="toggleBucket(r)")
										i.la(:class="{'la-check-square':bucketHash[r.id], 'la-stop':!bucketHash[r.id]}")
								td {{ r.id }}
								td
									entity(v-if="r.publisher", :id="r.publisher.id", :name="r.publisher.name", type="publisher")
								td
									span.badge.badge-success(v-if="r.active") Active
									span.badge.badge-danger(v-if="!r.active") Inactive
								td {{ r.name }}
								//td
									entity(:id="r.placement.id", :name="r.placement.name", type="placement")
								//td
									entity(:id="r.placement.offer.id", :name="r.placement.offer.name", type="offer")
								td.actions
									router-link.btn.btn-action.btn-secondary(:to="{name:'tag-edit', params: {id:r.id}}", v-b-tooltip.hover.bottom, title="Edit")
										i.la.la-pencil
									router-link.btn.btn-action.btn-info(:to="{name:'tag-add', query:{copy_id:r.id}}", v-b-tooltip.hover.bottom, title="Duplicate")
										i.la.la-copy

					paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<style lang="scss">
.mmp {
	text-transform: uppercase;
	font-weight: bold;
}

.category {
	position: absolute;
	bottom: 2px;
	left: 2px;
	font-size: 10px;
	color: #aaa;
}

.created_at {
	position: absolute;
	bottom: 2px;
	right: 2px;
	font-size: 10px;
	color: #aaa;
}
</style>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import QuickConnectOffersModal from "@/views/components/QuickConnectOffersModal";

export default {
	name: 'TagList',
	async beforeRouteEnter(to, from, next){
		let advertiserOptions = await Vue.$dwData.advertiser.getOptions();
		next(vm => {
			vm.advertiserOptions = advertiserOptions;
			vm.loadData();
			return vm;
		});
	},
	data(){
		return {
			busy: false,
			selectedTab: 'search',
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			addAdvertiser: null,
			filters: {
				keyword: '',
				status: '',
				platform: '',
				countries: '',
				advertiser_id: []
			},
			bucket: [],
			bucketHash: {},
			records: [],
			filtersExpanded: false,
			offerTypeOptions: Vue.$dwData.general.offerTypeOptions,
			offerStatusOptions: [{
				v: null,
				t: '- All -',
			}, ...Vue.$dwData.general.offerStatusOptions.filter(o => o.v !== 'draft')],
			payoutModelOptions: Vue.$dwData.general.payoutModelOptions,
		};
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Automation'},
			{title: 'Auto-Campaigns'}
		]);
	},
	methods: {


		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
				status: this.filters.status,
				platform: this.filters.platform,
				countries: this.filters.countries,
			};
			try {
				let resp = await this.$api.get('/tags/getList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		}
	}
}
</script>
